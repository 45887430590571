<template>
  <div id="form-wrapper">
    <form class="vue-form" @submit.prevent="submit">

      <fieldset>
        <div id="name">
          <div class="firstName">
            <!-- <label class="label" for="name">Name</label> -->
            <p class="error-message" v-if="!validFirstName && firstName.allowValidation">required</p>
            <input type="text" name="firstName" id="firstName" placeholder="First Name" required v-model="firstName.value" v-on:blur="firstName.allowValidation = true" v-on:focus="firstName.allowValidation = false">
          </div>
          <div class="lastName">
            <!-- <label class="label" for="name">Name</label> -->
            <p class="error-message" v-if="!validLastName && lastName.allowValidation">required</p>
            <input type="text" name="lastName" id="lastName" placeholder="Last Name" required v-model="lastName.value" v-on:blur="lastName.allowValidation = true" v-on:focus="lastName.allowValidation = false">
          </div>
        </div>
        <div>
          <!-- <label class="label" for="email">Email</label> -->
          <p class="error-message" v-if="!validEmail && email.allowValidation">Please enter a valid email address.</p>
          <input type="email" name="email" id="email" placeholder="your.email@mail.com" required :class="{ email , error: !email.valid }" v-model="email.value" v-on:blur="email.allowValidation = true" v-on:focus="email.allowValidation = false">
        </div>
        <div>
          <!-- <label class="label" for="email">Email</label> -->
          <p class="error-message" v-if="!validSubject && subject.allowValidation">required</p>
          <input type="text" name="subject" id="subject" placeholder="Subject" required :class="{ subject , error: !subject.valid }" v-model="subject.value" v-on:blur="subject.allowValidation = true" v-on:focus="subject.allowValidation = false">
        </div>
        <!-- <div>
          <label class="label" for="portfolio">Portfolio</label>
          <p class="error-message" v-if="!validPortfolioLink && portfolio.allowValidation">Please enter a valid url.</p>
          <input type="portfolio" name="portfolio" id="portfolio" placeholder="Link to Portfolio/Website" pattern="https://.*" size="30" required :class="{ portfolio , error: !portfolio.valid }" v-model="portfolio.value" v-on:blur="portfolio.allowValidation = true" v-on:focus="portfolio.allowValidation = false">
        </div> -->
        <div>
          <!-- <label class="label" for="textarea">Message</label> -->
          <p class="error-message" v-if="!validMessage && message.allowValidation">Message should be longer than 60 characters</p>
          <!-- <span class="counter">{{ message.value.length }} / {{ message.maxlength }}</span> -->
          <textarea class="message" name="textarea" id="textarea" required v-model="message.value" v-on:blur="message.allowValidation = true" v-on:focus="message.allowValidation = false"></textarea>
        </div>
        <!-- <div id="CVUpload">
          <label class="CVupload" for="CV">
            <div class="upload-btn"><img src="../assets/imgs/up-arrow.svg" alt=""></div>
            <span class="btn-label">Upload CV</span>
            <input type="file" id="CV" name="CV" size="30" required>
          </label>
        </div> -->
        <div class="TsAndCs">
          <label class="label">
            <input type="checkbox" name="TsAndCs" v-model="privacyAgreement.value">
            I have read and agree to the &nbsp;<router-link to="/privacy" class="privacy-policy"> privacy policy</router-link>
            <div class="checkmark"></div>
          </label>
          <p class="error-message" v-if="!agreedToPrivacy && privacyAgreement.allowValidation">you must agree to our privacy policy</p>

        </div>
        <div id="submitBtn" class="LH_btn">
          <input type="submit" value="Send ✦">
          <p class="confirmationMsg" v-if="submitted">Your message has been sent, we will get back to you soon.</p>
        </div>
      </fieldset>
    </form>
  </div>
</template>

<script>
import{db} from '../firebase'
import {setDoc, doc} from "firebase/firestore";
export default {
  data(){
    return{

      firstName: {
        value: "",
        allowValidation: false
      },
      lastName: {
        value: "",
        allowValidation: false
      },
      subject: {
        value: "",
        allowValidation: false
      },
      email: {
        value: "",
        allowValidation: false
      },
      portfolio: {
        value: "",
        allowValidation: false
      },
      message: {
        value: ``,
        maxlength: 2500,
        allowValidation: false
      },
      privacyAgreement: {
        value: false,
        allowValidation: false
      },
      validForm: false,
      submitted: false

    }
  },
  props: [
  ],
  computed: {
    validFirstName (){
      return this.firstName.value ? true : false
    },
    validLastName (){
      return this.lastName.value ? true : false
    },
    validEmail (){
      return this.isEmail(this.email.value)
    },
    validSubject (){
      return this.subject.value ? true : false
    },
    validPortfolioLink (){
      return this.isURL(this.portfolio.value)
    },
    validMessage (){
      return this.message.value.length >= 50 ? true : false
    },
    agreedToPrivacy (){
      return this.privacyAgreement.value ? true : false
    },
  },
  // Regular expression from W3C HTML5.2 input specification:
// https://www.w3.org/TR/html/sec-forms.html#email-state-typeemail

  methods: {
    // submit form handler
    async submit() {
      this.validForm = this.validFirstName && this.validLastName && this.validEmail && this.validMessage && this.privacyAgreement.value;
      if(this.validForm){
        await setDoc(doc(db, "ContactForm", this.firstName.value + this.lastName.value + new Date()), {
          firstName: this.firstName.value,
          lastName: this.lastName.value,
          subject: this.subject.value,
          email: this.email.value,
          message: this.message.value,
          time: new Date(),
        });
        // const messagesRef = db.collection('messages')
        // messagesRef.add(
        //   {
        //     name: this.name.value,
        //     email: this.email.value,
        //     message: this.message.value,
        //     time: new Date(),
        //   },
        // )
        this.firstName.value = ''
        this.firstName.allowValidation = false
        this.lastName.value = ''
        this.lastName.allowValidation = false
        this.email.value = ''
        this.email.allowValidation = false
        this.subject.value = ''
        this.subject.allowValidation = false
        this.message.value = ''
        this.message.allowValidation = false
        // this.snackbar = false
        this.submitted = true
      }else if(!this.privacyAgreement.value){
        this.privacyAgreement.allowValidation = true
      }
    },
    // check for valid email address
    isEmail: function(value) {
      const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return emailRegExp.test(value);
    },
    isURL: function(value){
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return pattern.test(value);

    }
  }

}
</script>

<style lang="scss" scoped>
.vue-form {
  @include for-phone-only(){
    width: 100%;
    margin: 20px auto;
  }
  @include for-large-desktop-only(){
    background: none;
  }
  width: 600px;
  padding: 15px 30px;
  margin: 50px auto;
  background-color: #00000066;
  border-radius: 25px;
  backdrop-filter: blur(10px);
  font-family: $SansFont;
  fieldset{
    border: none;
  }
  div {
    position: relative;
    padding: 1em 0 0 0;
    margin: 25px 0;
  }
  .label {
    color: #ffffff;
    // margin-bottom: 10px;
    display: inline-block;
    line-height: 2rem;
    font-size: 0.8em;
  }
  input{
    @include for-phone-only(){
      width: 100%;
      border-radius: 0;
    }
    color: #ffffff;
    display: block;
    width: 100%;
    padding: 10px 15px;
    border: none;
    border-bottom: 1px solid #cfd9db;
    background: none;
    font-family: $SansFont;
  }
  textarea{
    @include for-phone-only(){
      width: 100%;
      margin: 20px 0;
    }
    color: #ffffff;
    display: block;
    width: 100%;
    appearance: none;
    background: none;
    padding: 10px 3%;
    border: 1px solid #cfd9db;
    border-radius: 0.25em;
    min-height: 120px;
    resize: vertical;
    overflow: auto;
  }
  #name{
    @include for-phone-only(){
      display: block;
    }
    padding: 1em 0 0 0;
    display: flex;
    justify-content: space-between;
    .firstName, .lastName{
      @include for-phone-only(){
        width: 100%;
        margin: 10px 0;
      }
      margin: 0;
      width: calc(50% - 5px);
    }
  }
  .error-message {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0px;
    padding: 0;
    color: #ea3d66;
    font-size: .7rem;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 0.25em;
  }
  .counter{
    font-size: 0.7rem;
    float: right;
    line-height: 2rem;
    color: #94aab0;
  }
  .confirmationMsg{
    display: inline-block;
    margin: 0px;
    padding: 0;
    font-size: .7rem;
    margin: 0 0 0 1rem;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 0.25em;
  }
  #CVUpload{
    width: 100%;
    height: 60px;
    padding: 8px;
    border: solid 2px #ffffff;
    border-radius: 40px;
    .CVupload{
      cursor: pointer;
      text-align: left;
      margin: 0;
      display: flex;
      align-items: center;
    }
    .upload-btn{
      margin: 0;
      padding: 8px;
      width: 40px;
      height: 40px;
      margin: 0 1em 0 0;
      border: 2px solid #fff;
      border-radius: 30px;
    }
  }
  .TsAndCs{
    text-align: block;
    padding: 0;
    margin: 0 0 1em 0;
    .label{
      @include for-phone-only(){
        font-size: 0.7em;
      }
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    a{
      display: inline-block;
      text-decoration: underline;
    }
  }
  .checkmark {
    top: -2px;
    left: 0;
    height: 20px;
    width: 20px;
    border-radius: 4px;
    border: solid 1px #aaa;
    order: -1;
    margin: 0;
  }
}
.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus{
  outline: none;
  color: #ffffff;
  border-color: #ffffff;
  font-family: $SansFont;
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    font-family: $SansFont;
}
}
.vue-form input[type="checkbox"] {
  width: 0;
  height: 0;
  opacity: 0;
  background: none;
  padding: 1.2em;
  margin: 0.2em;
  cursor: pointer;
  display: initial;
}

label:hover input ~ .checkmark {
    background: none;
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
label input:checked ~ .checkmark {
    background-color: #ffffff;
}
label input:checked ~ .checkmark:after {
display: block;
}
label .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid #000000;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.vue-form input[type="file"] {
  border: none;
  width: 50%;
  margin: 0 0 0 auto;
  padding: 0;
  background: none;
  color: #ffffff;
}
.vue-form input[type="file"]::file-selector-button {
  display: none;
  padding: .2em .4em;
  border-radius: .2em;
}
.vue-form input[type="submit"] {
  font-family: inherit;
  font-weight: 700;
  width: initial;
  border: none;
  display: inline-block;
  background: #1b1b1b;
  border-radius: 3rem;
  padding: .6em 1.8em .5em 1.8em;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#submitBtn{
  margin: 0 auto;
  padding: 3px;
}

</style>