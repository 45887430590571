<template>
  <div id="contact">
    <div class="gemholder">
      <img class="gem" id="gem1" src="../assets/imgs/gems/crystal3_00008.png" alt="crystal-1">
    </div>
    <div class="gemholder">
      <img class="gem" id="gem2" src="../assets/imgs/gems/Crystal4_00017.png" alt="crystal-2">
    </div>
    <div class="content">
        <div class="intro-text">
          <h2>Get in touch</h2>
        </div>
        <contactForm />
        <!-- <div id="map-wrapper">
        <div id="map">
        </div>
      </div> -->
      </div>
    </div>
</template>

<script>
import contactForm from '../components/ContactForm.vue';
// import { Loader } from "@googlemaps/js-api-loader";
// import icon from "../assets/imgs/Map-marker.svg"
export default {
  components: {
    contactForm
  },
  mounted() {
    // this.initMap()
  },
  methods: {
    // initMap() {
    //   const loader = new Loader({
    //     apiKey: "AIzaSyA7ujWBpd8KZF3YOAUPBfqVhyk9jRSNdTM",
    //     version: "weekly",
    //   });
    //   const position = {
    //     lat: 52.2852,
    //     lng: -1.5201
    //   }
    //   const mapOptions = {
    //     mapId: "21c087f0a6387002",
    //     center: {
    //       lat: position.lat,
    //       lng: position.lng
    //     },
    //     zoom: 14
    //   };

    //   loader.load().then((google) => {
    //     const map = new google.maps.Map(document.getElementById("map"), mapOptions)
    //     const icon = {
    //       url: require("../assets/imgs/Map-marker.svg"), // url
    //       scaledSize: new google.maps.Size(70, 70), // scaled size
    //       origin: new google.maps.Point(7,0), // origin
    //       anchor: new google.maps.Point(50, 70) // anchor
    //     };
    //     new google.maps.Marker({
    //       position: { lat: position.lat, lng: position.lng },
    //       icon: icon,
    //       map,
    //     })
    //   });
    // }
  }
}
</script>

<style lang="scss">
#contact {
  overflow: hidden;
  flex-grow: 1;

  .content {
    align-self: center;
  }
}

// .gem{
//   position: absolute;
//   top: 0;

// }
// #gem1{
//   @include for-phone-only(){
//     transform: translateX(-40%) translateY(-30%);
//   }
//   left: 0;
//   width: 70%;
//   transform: translateX(-40%) translateY(-40%);
// }
// #gem2{
//   @include for-phone-only(){
//     transform: translateX(40%) translateY(-10%);
//   }
//   right: 0;
//   width: 70%;
//   transform: translateX(40%) translateY(20%);
// }
// #map-wrapper{
//   height: 40vh;
//   padding: 1em 0;
// }
// #map{
//   height: 100%;
// }
</style>